import { defineStore } from 'pinia'
import router from "@/router";
import WebsocketApiClient from '@/api/websocket';
import ConversationService from "@/services/conversation";
import MediaService from "@/services/media";

export const useAppStore = defineStore('app', {
  state: () => ({
    status: null,
    company: null,
    campaign: null,
    responseAudio: [],
    media: null,
    finishedFlag: false,
    receivedFirstAudio: false,
    textResponse: "",
    lastResponse: "",
    rc: false,
    showRecordButton: true,
    waitUntilComplete: false,
    replyCounter: 1,
    recordCounter: 1,
    sendCounter: 1,
    language: "es",
    iconUrl: "@/assets/Avatar_Miguel.png",
    agentName: "",
    companyName: "",
    welcomeTitle: "",
    description: "",
    welcomeFooter: "",
    closureContent: "",
    textOnly: true,
    _apiClient: null,
    _logger: null,
    _conversationService: null,
    _mediaService: null,
  }),
  actions: {
    closure() {
      setTimeout(() => {
        router.push({ name: 'closure' })
      }, 5000)
    },
    apiClient() {
      if (!this._apiClient) {
        this._apiClient = new WebsocketApiClient({
          logger: this._logger,
          apiPrefix: import.meta.env.VITE_API_PREFIX,
          apiHostName: import.meta.env.VITE_API_HOSTNAME,
          companyName: this.company,
          campaignName: this.campaign,
        });
      }
      return this._apiClient;
    },
    mediaService() {
      if (!this._mediaService) {
        this._mediaService = new MediaService(this, this._logger, this.apiClient());
      }
      return this._mediaService;
    },
    conversationService(locale) {
      if (!this._conversationService) {
        this._conversationService = new ConversationService(this, this._logger, this.apiClient(), locale);
      }
      return this._conversationService;
    },
  },
})
