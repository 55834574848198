
<template>
  <v-sheet class="ma-2 pa-2" color="background">
    <div fluid>
      <v-col v-if="appStore.status === STATUS.Waiting" cols="6" align-self="center" align="center">
        <LoaderCircles />
      </v-col>
      <v-col v-if="appStore.status === STATUS.Reading" cols="12" sm="8" md="6" align-self="center" align="left">
        <v-sheet style="margin-top: 50px;" color="background">
          <p>{{ appStore.textResponse }}</p>
        </v-sheet>
      </v-col>
      <div align-self="center" jusify="center" class="fill-height" style="width: 60vw; height: 20vh;">
        <div id="siriwave"></div>
      </div>
    </div>
  </v-sheet>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { event } from 'vue-gtag'
import SiriWave from "siriwave";

import LoaderCircles from './LoaderCircles.vue'
import { useAppStore } from "../store/app";
import { STATUS } from "../store/constants";

const emit = defineEmits(['audioStarted', 'audioEnded'])
const appStore = useAppStore()
const logger = inject('vuejs3-logger')

const PLAYBACK_RATE = 0.95;
const GAIN_RATE = 1.8;

// for legacy browsers
const audioContext = new (window.AudioContext || window.webkitAudioContext)();
// Audio analyzer
const analyser = audioContext.createAnalyser();
const sampleRate = audioContext.sampleRate;
const approxVisualisationUpdateFrequency = 60;
// Define your own weightings array
const weightings = new Array(1024).fill(0); // For example, an array of 1024 zeros

let spectrum, dBASpectrum, siriWave = null, SOUND_START = 0, SOUND_END = 0, CHUNK_COUNT = 0;

function uint8TodB(u) {
    // Convert a uint8 value to dB
    return 20 * (0.125 * u - 1);
}

function decodeCallback(buffer) {
    // Create an audio source node
    const source = audioContext.createBufferSource();
    // Set the buffer to the decoded audio data
    source.buffer = buffer;

    SOUND_END = buffer.duration;

    // Adjust the playback rate to RATE %
    source.playbackRate.value = PLAYBACK_RATE;

    // Icrease audio volume
    var gainNode = audioContext.createGain();
    gainNode.gain.value = GAIN_RATE;
    source.connect(gainNode);
    // Connect the gainNode to the audio context's destination (speakers)
    gainNode.connect(audioContext.destination);
    // connect to analyser
    source.connect(analyser);

    let totalNumberOfSamples = sampleRate / approxVisualisationUpdateFrequency;
    analyser.fftSize = 2 ** Math.floor(Math.log2(totalNumberOfSamples));
    spectrum = new Uint8Array(analyser.frequencyBinCount);
    dBASpectrum = new Float32Array(analyser.frequencyBinCount);
    let waveForm = new Uint8Array(analyser.frequencyBinCount);
    source.connect(analyser);
    initSiriwave();
    siriWave.start();

    const draw = () => {
        requestAnimationFrame(draw);
        analyser.getByteFrequencyData(spectrum);
        spectrum.forEach((byteLevel, idx) => {
            dBASpectrum[idx] = uint8TodB(byteLevel) + weightings[idx];
        });
        const
            highestPowerBin =
                dBASpectrum.reduce(([maxPower, iMax], y, idx) =>
                    y > maxPower ? [y, idx] : [maxPower, iMax], [-120, 0]
                )[1],
            maxPowerFrequency =
                highestPowerBin * (sampleRate / 2 / analyser.frequencyBinCount);

        siriWave.setSpeed(maxPowerFrequency / 10e+3);

        analyser.getByteTimeDomainData(waveForm);

        const amplitude = waveForm.reduce((acc, y) => Math.max(acc, y), 128) - 128;

        siriWave.setAmplitude(amplitude / 128 * 20);
    };

    // continue convo
    source.onended = function () {
        // Sound start is now sound end
        SOUND_START = SOUND_END;
        logger.debug(appStore.completeAudio)
        logger.debug(CHUNK_COUNT)
        logger.debug(appStore.responseAudio.length)
        if (CHUNK_COUNT == appStore.responseAudio.length && appStore.completeAudio) {
            emit("audioEnded", true);
        } else {
            playAudioChunks();
        }
        return;
    }

    // Start playing the audio
    source.start(0, SOUND_START);
    draw();
}

function initSiriwave() {
    if (appStore.status == STATUS.Playing && siriWave == null) {
        const container = document.querySelector("#siriwave");
        siriWave = new SiriWave({
            autostart: false,
            container: container,
            cover: true,
            height: Math.abs(container.scrollWidth * 0.6),
            style: "ios9",
            curveDefinition: [
                { color: "255, 255, 255", supportLine: true },
                { color: "160, 65, 214" },
                { color: "0, 215, 250" },
                { color: "85, 30, 235" }]
        });
    }
}

// Play audio chunks loops trough the base64-decoded
// chunks of audio stored in props... stream
async function playAudioChunks() {

    initSiriwave();

    // Wait until finished or next audio chunk arrived
    let new_chunks = appStore.responseAudio.length - CHUNK_COUNT
    // If indicated, ignore chunk count and wait until full audio is received
    if (appStore.waitUntilComplete) {
        if (!appStore.completeAudio) {
            setTimeout(playAudioChunks, 100)
            return
        }
    }
    if (new_chunks == 0 || (new_chunks <= 3 && !appStore.completeAudio)) {
        // Wait until change
        // logger.debug("waiting")
        setTimeout(playAudioChunks, 100)
        return
    }
    // Keep track of number of chunks played
    CHUNK_COUNT = appStore.responseAudio.length;

    // Combine chunks into single array buffer
    // TODO: Probably better to do this when messages arrive
    // Concatenate ArrayBuffers
    const joinedBuffer = appStore.responseAudio.reduce((acc, buffer) => {
        const tmp = new Uint8Array(acc.byteLength + buffer.byteLength);
        tmp.set(new Uint8Array(acc), 0);
        tmp.set(new Uint8Array(buffer), acc.byteLength);
        return tmp.buffer;
    }, new ArrayBuffer(0));

    // Decode the ArrayBuffer as an audio buffer
    audioContext.decodeAudioData(joinedBuffer, decodeCallback);
}

onMounted(() => {
    event('reply', { method: 'Google', event_label: "count: " + appStore.replyCounter++}),
    CHUNK_COUNT = 0;
    SOUND_START = 0;
    SOUND_END = 0;
    playAudioChunks();
})

</script>

<style scoped>
.circle-img {
    margin: 30px;
    border-radius: 50%;
    width: 90px;
    margin-bottom: 0px;
}
</style>
