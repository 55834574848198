<template>
    <v-row
        style="height: 48vh; width: 100%; background-color: rgb(var(--v-theme-background)) !important; overflow-y:hidden;"
        justify="center" align-content="center">
        <v-col cols="12" sm="12">
            <v-sheet color="background">
                <v-img aspect-ratio="1/1" cover src="@/assets/startRecording.png"
                    class="rounded-circle d-inline-block circle-img"
                    :style="{ boxShadow: `0px 0px ${volumeIndicator}px ${volumeIndicator}px #FC6D6D` }"></v-img>
                <p>{{ recorderTimer }}</p>
            </v-sheet>
        </v-col>
    </v-row>
    <v-row style="height: 48vh; width: 100%; background-color: white; overflow-y:hidden;" justify="center">
        <v-col cols="12" sm="12" align-content="space-evenly">
            <v-sheet>
                <v-img @click="cancel" aspect-ratio="1/1" cover src="@/assets/cancel.png"
                    class="rounded-circle d-inline-block circle-img" max-width="10%" style="margin-bottom: 0;"></v-img>
                <h5 @click="cancel">{{ t("message.clickCancel") }}</h5>
            </v-sheet>
            <v-sheet style="padding-top: 5%;">
                <v-img @click="stop" aspect-ratio="1/1" cover src="@/assets/send.png"
                    class="rounded-circle d-inline-block circle-img" max-width="30%" style="margin-bottom: 0;"></v-img>
                <h3 @click="stop">{{ t("message.clickSend") }}</h3>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useAppStore } from "../store/app";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

// import * as Flac from "libflacjs/dist/libflac"

const emit = defineEmits(['recordingStarted', 'recordingCancelled', 'recordingEnded', 'recordedAudioChunk'])
const appStore = useAppStore()

// Volume threshold, anything below this volume is not considered speech
const VOLUME_THRESHOLD = 60;
// How much time will wait after dtecting audio to auto continue convo
const AUTO_SEND_MILISECONDS = 1500;
// How long will wait to detect audio above threshold
const SPEECH_TIMEOUT = 8000;
// Time in miliseconds between audio evaluation
const VOLUME_CALLBACK_INTERVAL = 100;
// Time in miliseconds every recorder event data fires
const RECORDER_PERIOD_MILISECONDS = 100

let recorderTimer = ref("");
let recordedMiliseconds = ref(0);

var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Function to update the timer display
function updateTimer() {
    var seconds = 0;
    if (isChrome) {
        seconds = Math.round((recordedMiliseconds.value / 100));
    } else {
        seconds = Math.round((recordedMiliseconds.value / 1000));
    }
    var minutes = Math.floor(seconds / 60);
    seconds = seconds - (minutes * 60);

    // Display the time in format MM:SS
    recorderTimer.value = pad(minutes) + ":" + pad(seconds);

    // Increment time
    recordedMiliseconds.value += RECORDER_PERIOD_MILISECONDS;
}

// Function to add leading zero to single-digit numbers
function pad(num) {
    return (num < 10 ? '0' : '') + num;
}

const audioContext = new (window.AudioContext || window.webkitAudioContext)();
// console.log(audioContext.sampleRate);
const audioSource = audioContext.createMediaStreamSource(appStore.media);
// Audio analyzer
const analyser = audioContext.createAnalyser();
analyser.fftSize = 512;
analyser.minDecibels = -127;
analyser.maxDecibels = 0;
analyser.smoothingTimeConstant = 0.4;
audioSource.connect(analyser);
const volumes = new Uint8Array(analyser.frequencyBinCount);
const volumeIndicator = ref(0)
let recorder = null;
let stream = null;
let averageVolume = null;
let aboveThreshold = false;
let silenceTimer = 0;

let canceledRecording = false;

let volumeInterval = setInterval(volumeCallback, VOLUME_CALLBACK_INTERVAL);
function volumeCallback() {
    analyser.getByteFrequencyData(volumes);
    let volumeSum = 0;
    for (const volume of volumes)
        volumeSum += volume;
    averageVolume = Math.round(volumeSum / volumes.length);
    // console.log(averageVolume);
    // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
    volumeIndicator.value = (averageVolume * 100 / 127) - 30;
    if (averageVolume >= VOLUME_THRESHOLD) {
        silenceTimer = 0;
        aboveThreshold = true;
    } else if (silenceTimer >= AUTO_SEND_MILISECONDS && aboveThreshold) {
        // stop()
    } else if (silenceTimer >= SPEECH_TIMEOUT) {
        // TODO: Do something to check audio input
        // stop()
    } else {
        silenceTimer += VOLUME_CALLBACK_INTERVAL;
    }
};

function cancel() {
    canceledRecording = true;
    stop();
}

function stop() {
    clearInterval(volumeInterval)
    recorder.stop();
}

function getMediaRecorder(stream) {
    const options = {
        mimeType: null,
    };
    const types = [
        "audio/x-flac",
        "audio/mp4;codecs=opus",
        "audio/mp4",
        "audio/webm;codecs=opus",
        "audio/mpeg"
    ];
    for (const type of types) {
        if (MediaRecorder.isTypeSupported(type)) {
            // console.log(type);
            options.mimeType = type;
            break;
        }
    }
    if (options.mimeType == null) {
        alert("No supported mime types");
        return null
    }
    return new MediaRecorder(stream, options);
}

const tempChunks = [];

onMounted(async () => {
    stream = appStore.media;
    canceledRecording = false;
    recorder = getMediaRecorder(stream);
    recorder.onstart = (e) => {
        console.log("recorder started");
        emit("recordingStarted", recorder.mimeType);
    };
    recorder.ondataavailable = (e) => {
        if (canceledRecording) {
            return
        }
        // Update timer value
        updateTimer();
        // Send data through websocket as it is available
        console.log("Data size: " + e.data.size);
        emit("recordedAudioChunk", e.data);
        if (recorder.state != "recording") {
            console.log("Recorder is not recording anymore");
            //this.connection.close(1000);
        }
    };
    recorder.onstop = (e) => {
        console.log("recorder stopped");
        if (canceledRecording) {
            emit("recordingCancelled");
        } else {
            emit("recordingEnded");
        }
    };
    // Restart timer values
    recordedMiliseconds.value = 0;
    recorderTimer.value = "";
    recorder.start(RECORDER_PERIOD_MILISECONDS);
})
</script>

<style scoped>
.circle-img {
    margin: 30px;
    border-radius: 50%;
    width: 90px;
}
</style>
