/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Sentry tools
import * as Sentry from "@sentry/vue";

// Logger
import VueLogger from 'vuejs3-logger';

// Analytics
import VueGtag from "vue-gtag";

// Locales
import {createI18n} from "vue-i18n";

const isProduction = process.env.NODE_ENV === 'production';

const options = {
    isEnabled: true,
    logLevel: isProduction ? 'debug' : 'debug',
    stringifyArguments: true,
    showLogLevel: true,
    showMethodName: false,
    separator: '|',
    showConsoleColors: true
};

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: 'es',
    fallbackLocale: 'en',
    messages: {
        es: {
            message: {
                welcome_title: "Gracias por unirte a esta breve conversación operada por Curuba AI",
                welcome_body: "Haz clic en 'Iniciar' para comenzar la conversación. Asegúrate de estar en un entorno tranquilo donde puedas hablar.",
                welcome_body2: "Si te pide permiso para usar tu micrófono, haz clic en “permitir” para continuar.",
                welcome_body3: "Una vez que inicie la conversación, hablarás con un agente de inteligencia artificial.",
                welcome_body4: "",
                start_button: "Iniciar",
                continue_button: "Continuar",
                closure: "La conversación ha terminado, puedes cerrar esta ventana",
                intro_initial: "Hola. Comencemos con una rápida prueba de sonido. ¿Puedes escucharme?",
                intro_5seconds: "¿Puedes escucharme? Si puedes escucharme, simplemente habla o haz clic en el botón de pulgar arriba.",
                intro_10seconds: "Hola. ¿Puedes escucharme? Si puedes escucharme, haz clic en el botón de pulgar arriba.",
                check_caption: "Parece que estamos teniendo algunos problemas con el audio. Podemos intentarlo de nuevo en otro momento o podemos intentar solucionarlo ahora. ¿Qué te gustaría hacer?",
                check_btn1: "Verificar audio",
                check_btn2: "Reprogramar la conversación",
                reschedule_link: "Si deseas realizar esta conversación en otro momento, haz clic aquí.",
                reschedule_title: "Reprogramar conversación",
                reschedule_body: "TBD",
                mic_troubleshooting: "Ahora verifiquemos tu micrófono.\n\nAsegúrate de haber otorgado acceso a Curuba AI a tu micrófono.\n\nSi estás utilizando unos auriculares, asegúrate de que el volumen del micrófono no esté demasiado bajo, que estén conectados correctamente y, si tienen un botón de silencio, asegúrate de que esté apagado.",
                mic_header: "Probar Micrófono",
                mic_body: "Comienza a hablar y observa si las barras de abajo se llenan. ¿Puedes ver cómo se llenan las barras mientras hablas?",
                playground_body: "Parece que tu micrófono está funcionando correctamente. Haz clic en “Comenzar entrevista” para empezar.",
                start_btn: "Comenzar Entrevista",
                speakers_header: "Prueba de sonido",
                speakers_body: "Verifica que el volumen no esté demasiado bajo o en silencio. Revisa las conexiones de tus altavoces y auriculares en busca de cables sueltos. Asegúrate de que todos los cables estén enchufados. Si tienes múltiples conectores de 3.5 mm para enchufar, asegúrate de que todos los cables estén conectados en el conector correcto.",
                play_btn: "Reproducir sonido",
                speaking_header: "Habla ahora",
                speaking_body: "Para enviar tu respuesta, haz clic en el micrófono.",
                notes: "Espera un momento por favor...",
                clousure: "Así concluye la conversación, gracias por tu tiempo. Puedes cerrar esta ventana ahora",
                clickRecord: "Haz click para grabar",
                clickSend: "Enviar",
                clickCancel: "Cancelar",
                audioRecordingNotSupported: "Grabación de audio no soportado por su dispositivo",
                audioRecordingNotAllowed: "Se necesita acceso al micrófono para continuar con la conversación",
                invalidLink: "Link inválido",
            }
        },
        en: {
            message: {
                welcome_title: "Thanks for joining this short interview",
                welcome_body: "Click on “Start” to begin the conversation. Make sure you’re in a quiet, focused setting.",
                welcome_body2: "If you’re asked to grant permission to use your microphone hit “allow” to continue.",
                welcome_body3: "Once you start an AI interviewer will conduct the interview",
                welcome_body4: "",
                start_button: "Start",
                continue_button: "Continue",
                closure: "Finished conversation, you may now close this window",
                intro_initial: "TBD",
                intro_5seconds: "TBD",
                intro_10seconds: "TBD",
                check_caption: "TBD",
                check_btn1: "TBD",
                check_btn2: "TBD",
                reschedule_link: "TBD",
                reschedule_title: "TBD",
                reschedule_body: "TBD",
                mic_troubleshooting: "TBD",
                mic_header: "TBD",
                mic_body: "TBD",
                playground_body: "TBD",
                start_btn: "TBD",
                speakers_header: "TBD",
                speakers_body: "TBD",
                play_btn: "TBD",
                speaking_header: "Speak now",
                speaking_body: "Click on the microphone to submit",
                notes: "TBD",
                clousure: "This concludes our call, thank you for your time. You may now close this window.",
                clickRecord: "Click to record",
                clickSend: "Send",
                clickCancel: "Cancel",
                audioRecordingNotSupported: "Audio recording not supported by your device.",
                audioRecordingNotAllowed: "Allow microphone access to begin your interview. We need it to capture your responses and ensure a seamless experience. Your privacy is our priority.",
                invalidLink: "Invalid link",
            }
        }
    }
})

const app = createApp(App).use(VueLogger, options).use(i18n);

if (['production', 'development'].includes(process.env.NODE_ENV)) {
    app.use(VueGtag, {
        config: { id: "G-B1DC1VLZED" }
    })

    Sentry.init({
        app,
        dsn: "https://78c2dd8f79009a7393448e117f258ec9@o4506791890649088.ingest.sentry.io/4506791910113280",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/curuba\.ai\//],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.2,
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0.2, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: process.env.NODE_ENV,
    });
}

registerPlugins(app)

app.mount('#app')
