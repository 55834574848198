export default class MediaService {
  appStore = null;
  logger = null;

  constructor(appStore, logger) {
    this.appStore = appStore;
    this.logger = logger;
  }

  acquireMicrophone() {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
        },
      }).then(stream => {
        this.appStore.media = stream;
        resolve(stream);

      }).catch(err => {
        let key;
        if (err.name === 'ConstraintNotSatisfiedError') {
          key = "message.audioRecordingNotSupported";
        } else if (err.name === 'NotAllowedError') {
          key = "message.audioRecordingNotAllowed";
        } else {
          logger.error("unexpected error acquiring user media", JSON.stringify(err));
        }
        reject({error: err, messageKey: key});
      });
    });
  }
}
