import { STATUS, TRIGGER_CODES } from "@/store/constants";


const PING_PERIOD_MS = 15000;

export default class ConversationService {
  appStore = null;
  logger = null;
  apiClient = null;
  locale = null;

  constructor(appStore, logger, apiClient, locale) {
    this.appStore = appStore;
    this.logger = logger;
    this.apiClient = apiClient;
    this.locale = locale;
  }

  startConversation() {
    this.appStore.status = STATUS.Start;
    this.apiClient.connect(
      PING_PERIOD_MS,
      (message) => { this.stringMessage(message) },
      (blob) => { this.blobMessage(blob); },
    );
  }

  finishConversation() {
    this.appStore.status = STATUS.Finished;
    this.apiClient.close();
  }

  getConversationId() {
    return this.apiClient.conversationId;
  }

  getIntro() {
    this.appStore.status = STATUS.Waiting;
    this.apiClient.sendMessage("control", "getIntro");
  }

  recordingStarted() {
    this.appStore.$patch({
      textResponse: "",
      lastResponse: this.appStore.textResponse,
      responseAudio: [],
      rc: false,
      status: STATUS.Recording,
    });
    this.apiClient.sendMessage("control", "recordingStarted");
  }

  recordingCompleted() {
    this.appStore.status = STATUS.Waiting;
    this.apiClient.sendMessage("control", "recordingCompleted");
  }

  recordingStartedMime(mime) {
    this.apiClient.sendMessage("control", mime);
  }

  recordingCancelled() {
    this.apiClient.sendMessage("control", "recordingCanceled");
    this.appStore.$patch({
      textResponse: this.appStore.lastResponse,
      status: this.appStore.textOnly ? STATUS.Reading : STATUS.Playing,
      rc: true,
      showRecordButton: true,
    });
  }

  recordingChunk(chunk) {
    this.apiClient.sendAudioChunk(chunk);
  }

  stringMessage(response) {
    switch (response.type) {
      case "agentResponse":
        this.appStore.textResponse = response.content;
        if (this.appStore.status != STATUS.Start) {
          this.appStore.status = STATUS.Reading;
        }
        break;
      case "agentStreamResponse":
        this.appStore.textResponse += response.content;
        if (this.appStore.status != STATUS.Start) {
          this.appStore.status = STATUS.Reading;
        }
        break;
      case "control":
        if (response.content == "waitUntilComplete") {
          this.appStore.waitUntilComplete = true;
        }
        break;
      case "elevenAudio":
        if (response.content.audio == "") {
          break;
        }
        this.appStore.responseAudio.push(_decodeBase64Audio(response.content.audio));
        if (this.appStore.status != STATUS.Start) {
          this.appStore.status = STATUS.Playing;
        }
        break;
      case "audioStreamCompleted":
        this.appStore.rc = true;
        break;
      case "elevenAudioFinished":
        this.appStore.$patch({
          rc: true,
          status: STATUS.Playing,
        });
        if (
          response.triggers &&
          response.triggers.includes(TRIGGER_CODES.ConversationEnded)
        ) {
          this.appStore.showRecordButton = false;
          this.appStore.closure();
        }
        break;
      case "textStreamFinished":
        this.appStore.$patch({
          rc: true,
          status: STATUS.Reading,
        });
        if (
          response.triggers &&
          response.triggers.includes(TRIGGER_CODES.ConversationEnded)
        ) {
          this.appStore.showRecordButton = false;
          this.appStore.closure();
        }
        break;
      case "initialConfig":
        let config = JSON.parse(response.content);
        this.logger.info("Parsing config");
        this.appStore.$patch({
          language: config.language,
          iconUrl: config.iconUrl,
          agentName: config.agentName,
          companyName: config.companyName,
          welcomeTitle: config.welcomeTitle,
          description: config.description,
          welcomeFooter: config.welcomeFooter,
          closureContent: config.closureContent,
          textOnly: config.textOnly,
        });
        this.locale = config.language;
        break;
      default:
        this.logger.error("Unexpected string message", response);
    }
  }

  blobMessage(blob) {
    this.appStore.responseAudio.push(blob);
    if (this.appStore.status != STATUS.Start) {
      this.appStore.status = STATUS.Playing;
    }
  }
}

function _decodeBase64Audio(base64Data) {
  const binaryData = atob(base64Data);
  const length = binaryData.length;
  const buffer = new ArrayBuffer(length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < length; i++) {
    view[i] = binaryData.charCodeAt(i);
  }
  return buffer;
}
