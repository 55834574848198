<template>
    <v-sheet color="background" style="position: relative; height: 10vh;">
        <div class="loader-container">
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
        </div>
        <div class="loader-container">
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
        </div>
        <div class="loader-container">
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
        </div>
        <div class="loader-container">
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
        </div>
        <div class="loader-container">
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
            <div class="loader-wrapper">
                <div class="loader-circle"></div>
            </div>
        </div>
    </v-sheet>
</template>

<style scoped>
.loader-container {
    margin: 100px auto;
    position: fixed;
    left: 0;
    right: 0;
}

.loader-container * {
    will-change: transform, border, padding;
}

.loader-container:nth-child(2n) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.loader-container:nth-child(2) .loader-circle {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.loader-container:nth-child(3) .loader-circle {
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.loader-container:nth-child(4) .loader-circle {
    -webkit-animation-delay: -3s;
    animation-delay: -3s;
}

.loader-container:nth-child(5) .loader-circle {
    -webkit-animation-delay: -4s;
    animation-delay: -4s;
}

.loader-wrapper {
    position: absolute;
    width: 50px;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%;
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
}

.loader-wrapper:nth-child(1) {
    transform: rotate(30deg);
}

.loader-wrapper:nth-child(2) {
    transform: rotate(60deg);
}

.loader-wrapper:nth-child(3) {
    transform: rotate(90deg);
}

.loader-wrapper:nth-child(4) {
    transform: rotate(120deg);
}

.loader-wrapper:nth-child(5) {
    transform: rotate(150deg);
}

.loader-wrapper:nth-child(6) {
    transform: rotate(180deg);
}

.loader-wrapper:nth-child(7) {
    transform: rotate(210deg);
}

.loader-wrapper:nth-child(8) {
    transform: rotate(240deg);
}

.loader-wrapper:nth-child(9) {
    transform: rotate(270deg);
}

.loader-wrapper:nth-child(10) {
    transform: rotate(300deg);
}

.loader-wrapper:nth-child(11) {
    transform: rotate(330deg);
}

.loader-wrapper:nth-child(12) {
    transform: rotate(360deg);
}

.loader-wrapper:nth-child(13) {
    transform: rotate(390deg);
}

.loader-circle {
    width: 10px;
    height: 10px;
    margin: 0 auto;
    border: 1px solid #6320EE;
    border-radius: 50%;
    animation: loader-circle 6s linear infinite;
}

@keyframes loader-circle {
    0% {
        transform: translateY(0px);
        opacity: 0;
    }

    20% {
        opacity: 1;
        width: 10px;
        height: 10px;
        border-width: 1px;
    }

    40% {
        width: 0px;
        height: 0px;
        border-width: 7px;
    }

    60% {
        width: 20px;
        height: 20px;
        border-width: 3px;
    }

    80% {
        border-width: 1px;
        opacity: 1;
    }

    100% {
        transform: translateY(-130px);
        width: 20px;
        height: 20px;
        opacity: 0;
    }
}</style>