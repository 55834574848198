export const STATUS = {
  // User just opened the website, fixed intro
  Start: 'start',
  // Waiting for agent response
  Waiting: 'waiting',
  // User is recording the response
  Recording: 'recording',
  // Agent answered and audio is playing
  Playing: 'playing',
  // Agent replied with text and user is reading
  Reading: 'reading',
  // Conversation finished
  Finished: 'finished',
};

export const TRIGGER_CODES = {
  ConversationEnded: '[interview ended]',
}
