<template>
    <div class="text">
        <p v-if="appStore.description != ''" class="body-M">
            {{ appStore.description }}</p>
        <p class="body-M">
            {{ t("message.welcome_body") }}</p>
        <p class="body-M">
            {{ t("message.welcome_body2") }}</p>
        <p class="body-M">
            {{ t("message.welcome_body3") }}</p>
        <p class="body-M">
        <div v-html="footerDiv()"></div>
        </p>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useAppStore } from "../store/app";
import { event } from 'vue-gtag';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const appStore = useAppStore();

function footerDiv() {
    return appStore.welcomeFooter
}

onMounted(() => {
    event('welcome', { method: 'Google' });
})

</script>

<style scoped>
html,
body {
    font-family: "Inter";
}

p {
    text-align: left;
    padding-top: 1rem;
}

.text {
    max-height: 50%;
    max-width: 30rem;
}

.logo {
    margin-bottom: 2.5rem;
}

.cta {
    width: 100%;
    margin-top: 2.5rem;
}
</style>
