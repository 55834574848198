<template>
  <v-sheet v-if="status == STATUS.Start" class="ma-2 pa-2" color="background">
    <v-img v-if="iconUrl == 'Converse'" :width="80" aspect-ratio="1/1" cover src="@/assets/Converse.jpg"
      class="rounded-circle d-inline-block"></v-img>
    <v-img v-else-if="iconUrl == 'Falabella'" :width="80" aspect-ratio="1/1" cover src="@/assets/Falabella.png"
      class="rounded-circle d-inline-block"></v-img>
    <v-img v-else-if="iconUrl == 'Curuba'" :width="80" aspect-ratio="1/1" cover src="@/assets/Curuba.png"
      class="rounded-circle d-inline-block"></v-img>
    <v-img v-else :width="80" aspect-ratio="1/1" cover src="@/assets/Avatar_Miguel.png"
      class="rounded-circle d-inline-block"></v-img>
    <h5>{{ appStore.agentName }}</h5>
    <p>{{ appStore.companyName }}</p>
  </v-sheet>
  <v-sheet v-else class="ma-2 pa-2" color="background">
    <v-img :width="80" aspect-ratio="1/1" cover src="@/assets/Avatar_Miguel.png"
      class="rounded-circle d-inline-block"></v-img>
    <h5>{{ appStore.agentName }}</h5>
    <p>{{ appStore.companyName }}</p>
  </v-sheet>
</template>

<script setup>
import { useAppStore } from "@/store/app";
import { STATUS } from "../store/constants";
const appStore = useAppStore();
defineProps({
  status: String,
  iconUrl: String,
  agentName: String,
  companyName: String,
})
</script>
