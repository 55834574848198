const protocolName = "curuba";

export default class WebsocketApiClient {
  logger = null;
  companyName = null;
  campaignName = null;
  conversationId = null;
  onStringMessageCallback = null;
  onCloseCallback = null;
  apiUrl = null;
  connection = null;
  reconnect = null;
  pingTimeout = null;
  ping = null;

  constructor({ logger, apiPrefix, apiHostName, companyName, campaignName, conversationId }) {
    this.logger = logger;
    this.companyName = companyName;
    this.campaignName = campaignName;
    this.conversationId = conversationId;

    this.apiUrl = apiPrefix + apiHostName + "?company=" + companyName + "&campaign=" + campaignName;
    if (conversationId) {
      this.apiUrl += "&conversationId=" + conversationId;
    }
  }

  connect(pingTimeout, onStringMessageCallback, onBlobMessageCallback, onCloseCallback) {
    this.logger.info({ pingTimeout, onStringMessageCallback, onBlobMessageCallback, onCloseCallback });
    this.pingTimeout = pingTimeout;
    this.onStringMessageCallback = onStringMessageCallback;
    this.onBlobMessageCallback = onBlobMessageCallback;
    this.onCloseCallback = onCloseCallback;
    this.reconnect = true;

    this._connect();
  }

  _connect() {
    this.connection = new WebSocket(this.apiUrl, protocolName);
    this.connection.onopen = this._onOpen.bind(this);
    this.connection.onclose = this._onClose.bind(this);
    this.connection.onmessage = this._onMessage.bind(this);
    this.connection.onerror = this._onError.bind(this);
  }

  close() {
    this.reconnect = false;
    this.connection.close();
    this.connection = null;
  }

  sendAudioChunk(chunk) {
    this.connection.send(chunk);
  }

  sendMessage(type, content) {
    let message = JSON.stringify({
      version: "0.0.1",
      conversationId: this.conversationId,
      type: type,
      content: content,
    });
    this.logger.debug(message);
    this.connection.send(message);
  }

  _onMessage(event) {
    if (typeof event.data == "string") {
      const response = JSON.parse(event.data);
      this.logger.debug("Response", response);
      if (this.conversationId && this.conversationId != response.conversationId) {
        this.logger.error("Conversation ID does not match response");
      }
      this.conversationId = response.conversationId;
      if (this.onStringMessageCallback) {
        this.onStringMessageCallback(response);
      }

    } else if (event.data instanceof Blob) {
      if (this.onBlobMessageCallback) {
        this.onBlobMessageCallback(event.data);
      }

    } else {
      this.logger.error("Unexpected message type", typeof event.data, event.data);
    }
  }

  _onOpen(event) {
    this.logger.debug("opened websocket connection: ", event, this.pingTimeout);
    this.ping = setInterval(this._pingServer.bind(this), this.pingTimeout);
  }

  _onClose(event) {
    this.logger.debug("closed websocket connection", event);
    if (this.onCloseCallback) {
      this.onCloseCallback(event);
    }
    clearInterval(this.ping);
    if (this.reconnect) {
      setTimeout(this._connect, 1000);
    }
  }

  _onError(error) {
    this.logger.error("webSocket error:", error);
  }

  _pingServer() {
    this.sendMessage("control", "ping");
  }
}
