// Composables
import { createRouter, createWebHistory } from 'vue-router'
import Welcome from "../views/Welcome.vue";
import Conversation from "../views/Coversation.vue";
import Closure from "../views/Closure.vue";

const routes = [
  {
    name: 'welcome',
    path: '/:company/:campaign',
    component: Welcome,
  },
  {
    name: 'conversation',
    path: '/conversations/:id',
    component: Conversation,
  },
  {
    name: 'closure',
    path: '/thank-you',
    component: Closure,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
