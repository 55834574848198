<template>
  <v-app>
    <v-main>
      <v-container fluid color="background" class="fill-height flex-column" align="center">
        <v-row style="width: 100%;" justify="center" align-content="center">
          <v-col cols="12" sm="12">
            <Avatar :status="appStore.status" :iconUrl="appStore.iconUrl" :agentName="appStore.agentName" :companyName="appStore.companyName" />
          </v-col>
        </v-row>
        <v-row style="height: 40vh; width: 100%; overflow-y: scroll;" justify="center" align-content="center">
          <v-col cols="12" sm="12" style="height: 100%;">
            <v-sheet class="ma-2 pa-2" color="background">
              <div>
                <WelcomeMessage />
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row style="width: 100%;" justify="center" align-content="center">
          <v-col cols="12" sm="12">
            <v-sheet class="ma-2 pa-2" color="background">
              <v-btn class="v-btn v-btn--primary" style="width: 100%;" block @click="start">
                {{ t("message.start_button") }}
              </v-btn>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { onMounted, inject } from "vue";
import { useRoute } from 'vue-router'
import { event } from "vue-gtag";
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n();

import Avatar from '@/components/Avatar.vue';
import WelcomeMessage from '@/components/WelcomeMessage.vue'
import { useAppStore } from "@/store/app";
import router from "@/router";


const route = useRoute();
const logger = inject("vuejs3-logger");
const appStore = useAppStore();

function start() {
  event('start', { method: 'Google' });

  appStore.mediaService().acquireMicrophone().then(() => {
    event('allowed', { method: 'Google', event_category: 'user_media' });
    router.push({
      name: 'conversation',
      params: {
        id: appStore.conversationService(locale).getConversationId()
      }
    });

  }).catch(err => {
    if (err.error.name === 'ConstraintNotSatisfiedError') {
      event('not_supported', { method: 'Google', event_category: 'user_media' });
      alert(t(err.messageKey));

    } else if (err.error.name === 'NotAllowedError') {
      event('blocked', { method: 'Google', event_category: 'user_media' });
      alert(t(err.messageKey));

    } else {
      event('unexpected_error', { method: 'Google', event_category: 'user_media' });
    }
    appStore.closure();
  });
}

onMounted(() => {
  appStore.$patch({
    company: route.params.company,
    campaign: route.params.campaign,
    _logger: logger,
  });
  appStore.conversationService(locale).startConversation();
})
</script>
