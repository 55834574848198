<template>
  <v-app>
    <v-main>
      <v-container fluid color="background" class="fill-height flex-column" align="center">
        <v-row style="width: 100%;" justify="center" align-content="center">
          <v-col cols="12" sm="12">
            <Avatar :status="appStore.status" :iconUrl="appStore.iconUrl" :companyName="appStore.companyName" />
          </v-col>
        </v-row>
        <v-row style="height: 40vh; width: 100%; overflow-y: scroll;" justify="center" align-content="center">
          <v-col cols="12" sm="12" style="height: 100%;">
            <v-sheet class="ma-2 pa-2" color="background">
              <div>
                <h3 v-if="appStore.closureContent !== ''" v-html="appStore.closureContent"></h3>
                <h3 v-else>{{ t("message.closure") }}</h3>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { onMounted } from "vue";
import Avatar from '../components/Avatar.vue';
import { useAppStore } from "../store/app";
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n();
const appStore = useAppStore();

onMounted(() => {
  appStore.conversationService(locale).finishConversation();
})
</script>
