<template>
  <v-app>
    <v-main>
      <v-container v-if="appStore.status != STATUS.Recording" fluid color="background" class="fill-height flex-column"
        align="center">
        <v-row style="width: 100%;" justify="center" align-content="center">
          <v-col cols="12" sm="12">
            <Avatar :status="appStore.status" :iconUrl="appStore.iconUrl" :companyName="appStore.companyName" />
          </v-col>
        </v-row>
        <v-row style="height: 40vh; width: 100%; overflow-y: scroll;" justify="center" align-content="center">
          <v-col cols="12" sm="12" style="height: 100%;">
            <Visualizer v-if="appStore.status === STATUS.Reading || appStore.status === STATUS.Playing || appStore.status === STATUS.Waiting" />
          </v-col>
        </v-row>
        <v-row style="width: 100%;" justify="center" align-content="center">
          <v-col cols="12" sm="12">
            <v-sheet v-if="appStore.rc" color="background">
              <v-img @click="recordingStarted" :width="80" aspect-ratio="1/1" cover src="@/assets/startRecording.png"
                class="rounded-circle d-inline-block"></v-img>
              <p>{{ t("message.clickRecord") }}</p>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="appStore.status == STATUS.Recording" fluid class="fill-height flex-column" align="center"
        style="background-color: white; padding-left: 0; padding-right: 0">
        <Recorder @recordingStarted="recordingStartedMime" @recordingCancelled="recordingCancelled"
          @recordingEnded="recordingCompleted" @recordedAudioChunk="recordingChunk" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
import { onMounted } from "vue";
import { event } from "vue-gtag";

import Avatar from '../components/Avatar.vue';
import Visualizer from '../components/Visualizer.vue'
import Recorder from "../components/Recorder.vue";
import { useAppStore } from "../store/app";
import { STATUS } from "../store/constants";
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const appStore = useAppStore();

function recordingStarted() {
  event("record", { method: "Google", event_label: "count: " + appStore.recordCounter++ });
  appStore.conversationService(locale).recordingStarted();
}

function recordingCompleted() {
  event("send", { method: "Google", event_label: "count: " + appStore.sendCounter++ });
  appStore.conversationService(locale).recordingCompleted();
}

function recordingStartedMime(mime) {
  appStore.conversationService(locale).recordingStartedMime(mime);
}

function recordingCancelled() {
  event("cancelRecording", { method: "Google" });
  appStore.conversationService(locale).recordingCancelled();
}

function recordingChunk(chunk) {
  appStore.conversationService(locale).recordingChunk(chunk);
}

onMounted(() => {
  appStore.conversationService(locale).getIntro();
})
</script>
